@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
	font-family: 'Instrument Sans';
	src: url('../assets/fonts/InstrumentSans-Bold.woff2') format('woff2');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Instrument Sans';
	src: url('../assets/fonts/InstrumentSans-BoldItalic.woff2') format('woff2');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Instrument Sans';
	src: url('../assets/fonts/InstrumentSans-Italic.woff2') format('woff2');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Instrument Sans';
	src: url('../assets/fonts/InstrumentSans-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Instrument Sans Condensed';
	src: url('../assets/fonts/InstrumentSansCondensed-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Instrument Sans';
	src: url('../assets/fonts/InstrumentSans-Regular.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Instrument Sans';
	src: url('../assets/fonts/InstrumentSans-Regular.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Instrument Sans Condensed';
	src: url('../assets/fonts/InstrumentSansCondensed-MediumItalic.woff2') format('woff2');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Instrument Sans';
	src: url('../assets/fonts/InstrumentSans-MediumItalic.woff2') format('woff2');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Instrument Sans';
	src: url('../assets/fonts/InstrumentSans-Italic.woff2') format('woff2');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}


/* Hide Scrollbar for class*/
.hide-scrollbar::-webkit-scrollbar {
	display: none;
}
